import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import LoadingScreen from '../components/loading-screen';
import navConfig from '../layouts/dashboard/nav/config-navigation';
import Login from '../pages/LoginPage';
import findFirstAuthorizedItem from '../utils/findFirstAuthorizedItem';
import { useAuthContext } from './useAuthContext';
import { PATH_AUTH } from '../routes/paths';
export default function AuthGuard({ children }) {
    const { isAuthenticated, isInitialized } = useAuthContext();
    const { pathname } = useLocation();
    const { user } = useAuthContext();
    const PATH_AFTER_LOGIN = findFirstAuthorizedItem(navConfig[0]?.items, user?.permissions, user?.role?.code, user?.active, user?.email);
    const [requestedLocation, setRequestedLocation] = useState(null);
    if (!isInitialized) {
        return _jsx(LoadingScreen, {});
    }
    if (user?.active === false) {
        return _jsx(Navigate, { to: PATH_AUTH.emailVerification(user?.email) });
    }
    if (!isAuthenticated) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
        }
        return _jsx(Login, {});
    }
    if (requestedLocation && pathname !== requestedLocation && PATH_AFTER_LOGIN) {
        setRequestedLocation(null);
        return _jsx(Navigate, { to: PATH_AFTER_LOGIN });
    }
    return _jsxs(_Fragment, { children: [" ", children, " "] });
}
